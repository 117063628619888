
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  EOrderStatus,
  KEYS_TO_STATUSES,
  ORDER_STATUSES_LOCALIZATION_KEYS,
} from 'types';

import { PreparedOrder } from './Orders.vue';

type PreparedStatus = {
  text: string;
  disabled: boolean;
  help: string;
  key: (typeof ORDER_STATUSES_LOCALIZATION_KEYS)[number];
};

@Component({ name: 'OrderStatus' })
export default class OrderStatus extends Vue {
  @Prop()
  order: PreparedOrder;

  get preparedStatuses(): PreparedStatus[] {
    return ORDER_STATUSES_LOCALIZATION_KEYS.filter(
      (localizationKey) => localizationKey !== 'NOT_PAID',
    ).map((status) => ({
      text: this.$t('orders.statuses.' + status) as string,
      /**
       * Status will be disabled in the dropdown if the order
       * already has this status
       */
      disabled: this.order.status === this.$t('orders.statuses.' + status),
      help: this.$t('orders.statusesHelp.' + status) as string,
      key: status,
    }));
  }

  onStatusEdited(status: PreparedStatus) {
    const statusIdentifier: EOrderStatus = KEYS_TO_STATUSES[status.key];

    this.$emit('statusChanged', {
      orderId: this.order.id,
      status: statusIdentifier,
    });
  }
}
