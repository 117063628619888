
import { Component, Vue } from 'vue-property-decorator';
import { ICity, IDish, IRestaurant } from 'types';

import PhotoUploader from './PhotoUploader.vue';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

type InputDish = Omit<IDish, 'id' | 'position' | 'price'> & {
  position: string;
  price: string;
};

@Component({ name: 'DishAdd', components: { 'photo-uploader': PhotoUploader } })
export default class DishAdd extends Vue {
  form: InputDish = {
    dishCategoryId: localStorage.getItem(`${storagePrefix}-dishCategoryId`),
    name: '',
    name_en: '',
    position: '',
    description: '',
    description_en: '',
    price: '',
    telegramFileId: '',
    enabled: true,
    popularityCoefficient: 0,
  };

  cityId: ICity['id'] = null;
  photoUploading = false;

  async mounted() {
    const restaurantId = localStorage.getItem(`${storagePrefix}-restaurantId`);

    const restaurantRequest = await this.axios.get<IRestaurant>(
      `/restaurants/${restaurantId}`,
    );

    this.cityId = restaurantRequest?.data?.cityId;
  }

  async onSubmit() {
    await this.axios.post('/dishes', {
      ...this.form,
      position: parseInt(this.form.position),
      price: parseInt(this.form.price),
    });

    this.$router.push('/dishes');
  }

  onUploading() {
    this.photoUploading = true;
  }

  onUploaded(telegramFileId: string) {
    this.photoUploading = false;
    this.form.telegramFileId = telegramFileId;
  }

  /** Here goes validation */

  get nameValidation() {
    if (!this.form?.name) return null;

    return this.form.name.length < 256;
  }

  get nameEnValidation() {
    if (!this.form?.name_en) return null;

    return this.form.name_en.length < 256;
  }

  get descriptionValidation() {
    if (!this.form?.description) return null;

    return this.form.description.length < 256;
  }

  get descriptionEnValidation() {
    if (!this.form?.description_en) return null;

    return this.form.description_en.length < 256;
  }

  get priceValidation() {
    if (!this.form?.price) return null;

    return this.form.price !== '0';
  }

  get allValidationHasBeenPassed() {
    return (
      this.nameValidation !== false &&
      this.nameEnValidation !== false &&
      this.descriptionValidation !== false &&
      this.descriptionEnValidation !== false &&
      this.priceValidation !== false
    );
  }
}
