
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

const URLRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

@Component({ name: 'photo-uploader' })
export default class PhotoUploader extends Vue {
  @Prop()
  title: string;

  @Prop()
  cityId: string;

  @Prop()
  telegramFileId?: string | string[];

  @Prop({ default: false })
  multiple: boolean;

  @Prop({ default: '' })
  error: string;

  /**
   * Variables that are responsible for showing the image
   */
  useURL = false;
  files: File[] | File = null;
  images: { link: string; photoUrl: string | SourceBuffer }[] = [];
  link: string;
  radioOptions = [
    { text: 'URL', value: true },
    { text: 'Upload', value: false },
  ];

  /**
   * For showing indication
   */
  loading = true;
  uploading = false;
  uploadingState = null;
  uploadingProgress = 0;

  isUpdating = false;

  async onURLInserted(value) {
    if (value) {
      this.uploadingState = true;
      this.$emit('uploaded', value);
    } else {
      this.uploadingState = false;
      this.$emit('uploading');
    }
  }

  async onImageChosen() {
    this.uploadingState = null;
    this.uploading = true;

    this.$emit('uploading');

    const photoForm = new FormData(),
      params: { cityId?: string } = {};

    const files: File[] =
      this.files instanceof Array ? this.files : [this.files];

    files.forEach((file: File, index: number) => {
      photoForm.append('photo_' + index, file, file.name);
    });

    if (this.cityId) {
      params.cityId = this.cityId;
    }

    let data: any = null;

    try {
      const response = await this.axios.post(`/photo`, photoForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          this.uploadingProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
        params,
      });
      data = response.data;
    } catch (e) {
      //
    }

    if (!data) {
      this.error = 'Error while uploading image';
    }

    this.uploading = false;
    this.uploadingState = true;

    this.$emit('uploaded', this.multiple ? data : data[0]);
  }

  @Watch('telegramFileId')
  async onTelegramFileIdChanged() {
    if (!this.isUpdating) {
      this.isUpdating = true;
      await this.loadImage();
      this.isUpdating = false;
    }
  }

  @Watch('cityId')
  async onCityIdChanged() {
    if (!this.isUpdating) {
      this.isUpdating = true;
      await this.loadImage();
      this.isUpdating = false;
    }
  }

  async loadImage() {
    if (!this.telegramFileId || !this.cityId) return;

    const telegramFileIds =
      this.telegramFileId instanceof Array
        ? this.telegramFileId
        : [this.telegramFileId];

    this.images = [];

    for (const fileId of telegramFileIds) {
      if (URLRegex.test(fileId)) {
        this.useURL = true;

        this.images.push({
          link: fileId,
          photoUrl: fileId,
        });
      } else {
        this.useURL = false;

        try {
          const response = await this.axios.get(`/photo/`, {
            responseType: 'blob',
            params: {
              cityId: this.cityId,
              telegramFileId: fileId,
            },
          });

          const imageSource = URL.createObjectURL(response.data);

          this.images.push({
            link: fileId,
            photoUrl: imageSource,
          });
        } catch (e) {
          this.$bvToast.toast('Error while getting image', {
            title: this.$t('error').toString(),
            variant: 'danger',
          });
          this.images.push({
            link: null,
            photoUrl: null,
          });
        }
      }

      if (!this.multiple) break;
    }

    this.loading = false;
  }
}
