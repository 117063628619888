
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IDistrict } from 'types';

@Component({ name: 'Districts' })
export default class Regions extends Vue {
  @Prop() cityId!: number;

  regionCityId = '';

  fields = [
    {
      key: 'name',
      label: this.$t('region.name'),
      sortable: true,
    },
  ];

  districts: IDistrict[] = [];

  async mounted() {
    this.regionCityId = this.$route.params.cityId;
    const response = await this.axios.get(
      `/cities/${this.regionCityId}/districts`,
    );
    this.districts = response.data;
  }
}
