
import { Component, Vue } from 'vue-property-decorator';
import { ICity, ITag, RoleEnum } from 'types';

export type PreparedCity = { text: string; value: ICity };

@Component({ name: 'Tags' })
export default class Tags extends Vue {
  availableTags: ITag[] = [];
  cities: PreparedCity[] = [];

  async mounted() {
    if (this.isCityAdmin) this.updateTags(this.cityId);

    if (this.isAdmin) {
      const citiesResponse = await this.axios.get<ICity[]>('/cities');
      this.cities = citiesResponse.data?.map((city) => ({
        text: city.name,
        value: city,
      }));
    }
  }

  async onCreate() {
    await this.$router.push('/tag');
  }

  async onCityChanged(city: ICity) {
    await this.updateTags(city.id);
  }

  async updateTags(cityId: ICity['id']) {
    const url = '/tags/cities/' + cityId;

    const availableTagsResponse = await this.axios.get<ITag[]>(url);
    this.availableTags = availableTagsResponse.data;
  }

  async onTagSelected(tag: ITag) {
    await this.$router.push('/tags/' + tag.id);
  }

  async onTagDelete(tag: ITag) {
    await this.axios.delete<ITag[]>('/tags/' + tag.id);

    await this.updateTags(tag.cityId);
  }

  // Getters from the store

  get cityId() {
    return this.$store.getters.cityId;
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }
}
