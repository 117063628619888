
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoleEnum, ICity, IDish, IRestaurant } from 'types';

import PhotoUploader from './PhotoUploader.vue';
import TagPad from './TagPad.vue';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

@Component({
  name: 'Dish',
  components: { 'photo-uploader': PhotoUploader, 'tag-pad': TagPad },
})
export default class Dish extends Vue {
  @Prop()
  id: string;

  dish: IDish = null;
  cityId: ICity['id'] = null;

  photoUploading = false;
  deleteLinkHovered = false;

  availableTags = [];

  async mounted() {
    const restaurantId = localStorage.getItem(`${storagePrefix}-restaurantId`);

    const dishResponse = await this.axios.get<IDish>('/dishes/' + this.id);
    this.dish = dishResponse?.data;

    const restaurantResponse = await this.axios.get<IRestaurant>(
      `/restaurants/${restaurantId}`,
    );
    this.cityId = restaurantResponse?.data?.cityId;

    const availableTagsResponse = await this.axios.get(
      '/tags/cities/' + this.cityId,
    );
    this.availableTags = availableTagsResponse.data;
  }

  onTagsUpdated(tags) {
    this.dish.tags = tags;
  }

  onDelete() {
    if (confirm(this.$t('dish.confirm_delete').toString())) {
      this.axios
        .delete('/dishes/' + this.id)
        .then(() => this.$router.push({ path: '/dishes' }));
    }
  }

  onSubmit() {
    this.axios
      .put('/dishes/' + this.id, this.dish)
      .then(() => this.$router.push({ path: '/dishes' }));
  }

  onUploading() {
    this.photoUploading = true;
  }

  onUploaded(telegramFileId: string) {
    this.photoUploading = false;
    this.dish.telegramFileId = telegramFileId;
  }

  onDeleteHover(value) {
    this.deleteLinkHovered = value;
  }

  /** Here goes validation */

  get nameValidation() {
    if (!this.dish?.name) return null;

    return this.dish.name.length < 256;
  }

  get nameEnValidation() {
    if (!this.dish?.name_en) return null;

    return this.dish.name_en.length < 256;
  }

  get descriptionValidation() {
    if (!this.dish?.description) return null;

    return this.dish.description.length < 256;
  }

  get descriptionEnValidation() {
    if (!this.dish?.description_en) return null;

    return this.dish.description_en.length < 256;
  }

  get priceValidation() {
    if (!this.dish?.price) return null;

    return this.dish.price !== 0;
  }

  get allValidationHasBeenPassed() {
    return (
      this.nameValidation !== false &&
      this.nameEnValidation !== false &&
      this.descriptionValidation !== false &&
      this.descriptionEnValidation !== false &&
      this.priceValidation !== false
    );
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }
}
