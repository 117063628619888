
import { ICity, RoleEnum } from 'types';

export default {
  name: 'Restaurants',
  methods: {
    selected() {
      this.$router.push({ path: '/details/:recordId' });
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('restaurants.name'),
          sortable: true,
        },
        {
          key: 'name_en',
          label: this.$t('restaurants.name_en'),
          sortable: true,
        },
        {
          key: 'enabled',
          label: this.$t('restaurants.enabled'),
          sortable: true,
        },
        {
          key: 'position',
          label: this.$t('restaurants.position'),
          sortable: true,
        },
        {
          key: 'minOrderSum',
          label: this.$t('restaurants.minOrderSum'),
          sortable: false,
        },
        {
          key: 'deliveryTime',
          label: this.$t('restaurants.deliveryTime'),
          sortable: false,
        },
        {
          key: 'notificationUser',
          label: this.$t('restaurants.notificationAccount'),
        },
      ],
      restaurants: [],
      cities: [],
      selectedCity: null,
    };
  },
  computed: {
    getRestaurants() {
      return this.restaurants.slice().sort(function (a, b) {
        if (a.position > b.position) {
          return 1;
        }

        if (a.position < b.position) {
          return -1;
        }

        return a.name.localeCompare(b.name);
      });
    },
    roleId() {
      return this.$store.getters.roleId;
    },
    cityId() {
      return this.$store.getters.cityId;
    },
    isAdmin() {
      return this.$store.getters.roleId === RoleEnum.ADMIN;
    },
    isRestaurantOwner() {
      return this.$store.getters.roleId === RoleEnum.RESTAURANT_OWNER;
    },
    isCityAdmin() {
      return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
    },
    isOperator() {
      return this.$store.getters.roleId === RoleEnum.OPERATOR;
    },
  },
  watch: {
    selectedCity(city: ICity) {
      this.axios
        .get(`/restaurants/city/${city.id}`)
        .then(({ data }) => (this.restaurants = data));
    },
  },
  mounted() {
    if (this.isCityAdmin || this.isOperator) {
      this.axios.get('/restaurants/city/' + this.cityId).then(({ data }) => {
        this.restaurants = data;
      });
    }

    this.axios.get('/cities').then(({ data }) => {
      this.cities = (data as ICity[]).map((city) => ({
        value: city,
        text: city.name,
      }));
    });
  },
};
