
import { Component, Vue } from 'vue-property-decorator';
import * as _ from 'lodash';
import { RoleEnum } from 'types';

import VersionModal from './components/VersionModal.vue';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

@Component({
  name: 'App',
  components: { versionModal: VersionModal },
})
export default class App extends Vue {
  BOOSTRAP_MEDIUM_WIDTH = 768;

  /**
   * Checks if this is a phone
   */
  isSmallViewport = document.body.offsetWidth < this.BOOSTRAP_MEDIUM_WIDTH;

  sidebarVisible = false;

  get sidebarStatus() {
    return this.sidebarVisible;
  }

  set sidebarStatus(value) {
    if (this.isSmallViewport) this.sidebarVisible = value;
  }

  get title() {
    return process.env.VUE_APP_APP_TITLE;
  }

  get menuStyle() {
    return { 'background-color': process.env.VUE_APP_BACK_COLOR };
  }

  get isLoggedIn() {
    return this.$store.getters.isLoggedIn;
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }

  get isRestaurantOwner() {
    return this.$store.getters.roleId === RoleEnum.RESTAURANT_OWNER;
  }

  get restId() {
    return this.$store.getters.restId;
  }

  get cityId() {
    return this.$store.getters.cityId;
  }

  get allowedSections() {
    const roleId = this.$store.getters.roleId as RoleEnum;

    switch (roleId) {
      case RoleEnum.ADMIN:
        return [
          {
            to: '/stats',
            text: this.$t('menu.stats'),
          },
          {
            to: '/bots',
            text: this.$t('menu.bots'),
          },
          {
            to: '/cuisines',
            text: this.$t('menu.cuisines'),
          },
          {
            to: '/restaurants',
            text: this.$t('menu.restaurants'),
          },
          {
            to: '/reviews',
            text: this.$t('menu.reviews'),
          },
          {
            to: '/ads',
            text: this.$t('menu.ads'),
          },
          {
            to: '/cities',
            text: this.$t('menu.cities'),
          },
          {
            to: '/language/ru',
            text: this.$t('menu.russian'),
          },
          {
            to: '/language/en',
            text: this.$t('menu.english'),
          },
          {
            to: '/language/ka',
            text: this.$t('menu.georgian'),
          },
          {
            to: '/spam',
            text: this.$t('menu.spam'),
          },
          {
            to: '/users/top',
            text: this.$t('menu.top'),
          },
          {
            to: '/orders',
            text: this.$t('menu.orders'),
          },
          {
            to: '/tags',
            text: this.$t('menu.tags'),
          },
          {
            to: '/users',
            text: this.$t('menu.users'),
          },
          {
            to: '/logout',
            text: this.$t('menu.logout'),
          },
        ];
      case RoleEnum.CITY_ADMIN:
        return [
          {
            to: '/restaurants',
            text: this.$t('menu.restaurants'),
          },
          {
            to: '/reviews',
            text: this.$t('menu.reviews'),
          },
          {
            to: '/cuisines',
            text: this.$t('menu.cuisines'),
          },
          {
            to: '/ads',
            text: this.$t('menu.ads'),
          },
          {
            to: '/city/' + this.cityId,
            text: this.$t('menu.city'),
          },
          {
            to: '/orders',
            text: this.$t('menu.orders'),
          },
          {
            to: '/tags',
            text: this.$t('menu.tags'),
          },
          {
            to: '/stats',
            text: this.$t('menu.stats'),
          },
          {
            to: '/spam',
            text: this.$t('menu.spam'),
          },
          {
            to: '/users/top',
            text: this.$t('menu.top'),
          },
          {
            to: '/users',
            text: this.$t('menu.users'),
          },
          {
            to: '/logout',
            text: this.$t('menu.logout'),
          },
        ];
      case RoleEnum.RESTAURANT_OWNER:
        return [
          {
            to: '/restaurants/' + this.restId,
            text: this.$t('menu.myrest'),
          },
          {
            to: '/ads',
            text: this.$t('menu.ads'),
          },
          {
            to: '/orders',
            text: this.$t('menu.orders'),
          },
          {
            to: '/restaurants/' + this.restId + '/reviews',
            text: this.$t('menu.reviews'),
          },
          {
            to: '/logout',
            text: this.$t('menu.logout'),
          },
        ];
      case RoleEnum.OPERATOR:
        return [
          {
            to: '/restaurants',
            text: this.$t('menu.restaurants'),
          },
          {
            to: '/reviews',
            text: this.$t('menu.reviews'),
          },
          {
            to: '/city/' + this.cityId,
            text: this.$t('menu.city'),
          },
          {
            to: '/orders',
            text: this.$t('menu.orders'),
          },
          {
            to: '/logout',
            text: this.$t('menu.logout'),
          },
        ];
      default:
        return [];
    }
  }

  get showMenuMovedTooltip() {
    return (
      this.$store.state.showMenuHasBeenMovedTooltip &&
      !this.sidebarVisible &&
      this.$store.getters.isLoggedIn
    );
  }

  get appVersion() {
    return process.env.VUE_APP_VERSION;
  }

  mounted() {
    if (!this.isLoggedIn) {
      this.$router.push('/login');
      this.$store.commit('logout');
    }

    window.addEventListener('resize', () => {
      this.isSmallViewport =
        document.body.offsetWidth < this.BOOSTRAP_MEDIUM_WIDTH;
    });

    // Gets additional details for admins depending on their roles

    this.$store.dispatch('updateDetails');

    // Shows alert if important parameters is not set

    if (this.isAdmin) {
      this.$store.dispatch('getMissingGlobalSettings').then(() => {
        const { missingGlobalSettings } = this.$store.state;

        _.map(missingGlobalSettings, (message) => {
          this.$bvToast.toast(message, {
            toaster: 'b-toaster-bottom-right',
            title: 'A global setting is missing!',
            autoHideDelay: 20000, // 10s
            variant: 'danger',
          });
        });

        this.$store.commit('clearMissingGlobalSettings');
      });

      this.$store.dispatch('getMissingCityConfigParameters').then(() => {
        const { citiesWithMissingParameters } = this.$store.state;

        _.map(citiesWithMissingParameters, ([cityName, parameters]) => {
          this.$bvToast.toast(
            `${cityName} city is missing this set of config parameters: ${parameters}. Some functionallity might not work.`,
            {
              toaster: 'b-toaster-bottom-right',
              title: 'Important city config parameters are missing',
              autoHideDelay: 20000, // 10s
              variant: 'warning',
            },
          );
        });

        this.$store.commit('clearListOfMissingCityParameters');
      });
    }
  }
}
