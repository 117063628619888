
export default {
  name: 'Language',
  props: {
    id: String,
  },
  data() {
    return {
      submitted: false,
      objects: false,
      filter: '',
    };
  },
  mounted() {
    this.updateData();
  },
  beforeRouteUpdate(to, from, next) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.
    this.updateData(to.params.id);
    next();
  },
  computed: {
    isSubmitted: {
      get: function () {
        return this.submitted;
      },
      set: function (newValue) {
        this.submitted = newValue;
      },
    },
  },
  methods: {
    updateData(to) {
      to = to || this.id;
      this.axios.get(`/language/${to}`).then((response) => {
        this.objects = response.data;
      });
    },
    // onSubmit(evt, name, index) {
    onSubmit() {
      if (!this.isSubmitted) {
        this.isSubmitted = true;

        this.axios
          .post(`/language/${this.id}`, {
            data: this.objects,
          })
          .then(() => {
            // this.$router.go()
            setTimeout(() => {
              this.$router.go();
            }, 10000);
          });
        setTimeout(() => {
          this.$router.go();
        }, 15000);
      }
    },
  },
};
