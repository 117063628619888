
import { Component, Vue } from 'vue-property-decorator';

import { ILoginForm } from '../store/interfaces';

@Component({ name: 'Login' })
export default class Login extends Vue {
  form: ILoginForm = {
    username: '',
    password: '',
  };

  showPassword = false;

  async onSubmit() {
    await this.$store.dispatch('login', this.form);
    this.$router.push('/');
  }

  get errorValidation() {
    return !this.$store.state.status || this.$store.state.status === 'loading'
      ? null
      : this.$store.state.status !== 'error';
  }

  get isLoading() {
    return this.$store.state.status === 'loading';
  }
}
