
import { Component, Vue } from 'vue-property-decorator';
import { ApexOptions } from 'apexcharts';
import { ICity, RoleEnum } from 'types';

type OrderStatistics = {
  amountOfOrders: number;
  amountOfTwoOrders: number;
  amountOfThreeOrders: number;
  amountOfFourAndMoreOrders: number;
};

type Queries = {
  date: string;
  cityId: string;
  weeklyReport?: boolean;
  monthlyReport?: boolean;
};

type ChartData = {
  data: number[];
  columns: string[];
};

@Component({ name: 'Stats' })
export default class Stats extends Vue {
  newUsers = 0;
  executionTime = 0;
  activeUsers = 0;
  blockedUsersAmount = 0;
  cartsAmount = 0;
  orderStatistics: OrderStatistics = {
    amountOfOrders: 0,
    amountOfTwoOrders: 0,
    amountOfThreeOrders: 0,
    amountOfFourAndMoreOrders: 0,
  };

  scales = ['День', 'Неделя', 'Месяц'] as const;
  scale: (typeof this.scales)[number] = 'День';

  chartOptions: ApexOptions = {
    chart: { id: 'vuechart' },
    dataLabels: { enabled: true },
    colors: ['#F15065', '#545454'],
  };

  series = [];

  date: string = null;
  city = null;
  cities = [];

  async mounted() {
    await this.axios.get<ICity[]>('/cities').then(({ data }) => {
      this.cities = data.map((city) => ({
        value: city,
        text: city.name,
      }));
    });

    await this.updateStats();
  }

  updateStats<T>(date?: T) {
    const dateStringified =
      date && date instanceof String
        ? date
        : this.date
        ? this.date
        : new Date().toDateString();

    const params = {
      date: dateStringified,
      cityId: this.isCityAdmin ? this.cityId : this.city.id,
    } as Queries;

    if (this.scale === 'Неделя') params.weeklyReport = true;
    if (this.scale === 'Месяц') params.monthlyReport = true;

    // Updates chart

    this.axios.get<ChartData>('/stats/orders', { params }).then(({ data }) => {
      this.series = [{ name: 'orders', data: data.data }];

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: data.columns,
        },
      };

      this.chartOptions.dataLabels.enabled = this.scale !== 'День';
    });

    this.axios.get('/stats/newUsers', { params }).then((response) => {
      this.newUsers = response.data;
    });

    this.axios.get('/stats/executionTime', { params }).then((response) => {
      this.executionTime = response.data;
    });

    this.axios.get('/stats/activeUsers', { params }).then((response) => {
      this.activeUsers = response.data;
    });

    this.axios.get('/stats/ordersAmount', { params }).then((response) => {
      this.orderStatistics = response.data;
    });

    this.axios.get('/stats/blockedUsersAmount', { params }).then((response) => {
      this.blockedUsersAmount = response.data;
    });

    this.axios.get('/stats/cartsAmount', { params }).then((response) => {
      this.cartsAmount = response.data;
    });
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get cityId() {
    return this.$store.getters.cityId;
  }
}
