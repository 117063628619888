
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IAd, IRestaurant, RoleEnum } from 'types';
import { AxiosError } from 'axios';

import PhotoUploader from './PhotoUploader.vue';

type AdOptions = {
  text: string;
  telegramChatId: number;
  restaurantId: string;
  telegramFileIds: string[];
  publishTimestamp?: number;
  unpublishTimestamp?: number;
};

@Component({
  name: 'Ad',
  components: { 'photo-uploader': PhotoUploader },
})
export default class Ad extends Vue {
  @Prop() id: string;
  data: Partial<IAd> = {};
  telegramFileIds: string[] = [];
  restaurants = [];
  restaurantsList = [];
  publishTime = '';
  unpublishTime = '';
  publishContext = null;
  unpublishContext = null;
  chatId: number;
  sending = false;
  uploading = false;

  async mounted() {
    // this.id will be empty if an admin would like to create new ad
    // in other cases, there will be some value.

    if (this.id) {
      // Gets details of an specified ad

      const response = await this.axios.get<IAd>('/advertisements/' + this.id);

      this.data = {
        ...response.data,
        publishDate: new Date(response.data.publishDate),
        unpublishDate: new Date(response.data.unpublishDate),
      };

      // Formats dates and times into something that's human-readable

      this.publishTime =
        this.data.publishDate.getHours() +
        ':' +
        this.data.publishDate.getMinutes() +
        ':00';

      this.unpublishTime =
        this.data.unpublishDate.getHours() +
        ':' +
        this.data.unpublishDate.getMinutes() +
        ':00';
    }

    /**
     * Only admins can specify restaurant to advertise.
     *
     * Restaurant owners can use only their own restaurants.
     */

    if (this.isAdmin) {
      const response = await this.axios.get<IRestaurant[]>('/restaurants', {
        params: { enabled: true },
      });

      this.updateRestaurantsLists(response.data);
    }

    if (this.isCityAdmin) {
      const cityId = this.$store.getters.cityId as string;

      const response = await this.axios.get<IRestaurant[]>(
        `/restaurants/city/${cityId}`,
        { params: { enabled: true } },
      );

      this.updateRestaurantsLists(response.data);
    }

    // Gets chat where the advertisement must be published

    this.axios.get<number>('/advertisements/chat').then(({ data }) => {
      this.chatId = data;
    });
  }

  updateRestaurantsLists(restaurants: IRestaurant[] = []) {
    this.restaurantsList = restaurants;
    this.restaurants = restaurants.map((item) => ({
      value: item.id,
      text: item.name,
    }));
  }

  async onDelete() {
    await this.axios.delete(`/advertisements/${this.id}`);
    this.$router.push({ path: '/ads' });
  }

  onUploaded(telegramFileIds?: string[]) {
    this.uploading = false;

    if (!telegramFileIds) {
      this.$bvToast.toast('Error while uploading image', {
        title: 'Error',
        variant: 'danger',
      });
      return;
    }

    this.telegramFileIds = [];
    this.telegramFileIds.push(...telegramFileIds);
  }

  onUploading() {
    this.uploading = true;
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get isRestaurantOwner() {
    return this.$store.getters.roleId === RoleEnum.RESTAURANT_OWNER;
  }

  get roleId() {
    return this.$store.getters.roleId;
  }

  get cityId() {
    switch (this.roleId) {
      case RoleEnum.ADMIN:
        if (this.id) {
          const cityId = this?.data?.restaurant.id;

          return cityId;
        } else {
          const currentRestaurant = this.restaurantsList.find(
            ({ id }) => id === this.data.restaurantId,
          );

          return currentRestaurant?.cityId;
        }
      case RoleEnum.CITY_ADMIN:
        return this.$store.getters.cityId;
      case RoleEnum.RESTAURANT_OWNER:
        return this.$store.state.restaurant?.cityId;
      default:
        return null;
    }
  }

  get lengthValidation() {
    return this.data.text?.length >= 1024 ? false : null;
  }

  onPublishContext(ctx) {
    this.publishContext = ctx;
  }

  onUnpublishContext(ctx) {
    this.unpublishContext = ctx;
  }

  async onSubmit() {
    this.sending = true;

    const {
      text,
      restaurantId,
      publishDate: rawPublishDate,
      unpublishDate: rawUnpublishDate,
    } = this.data;

    // These values may be sent in string form, so we must parse them

    const publishDate =
      rawUnpublishDate && typeof rawPublishDate === 'string'
        ? new Date(Date.parse(rawPublishDate))
        : rawPublishDate;

    const unpublishDate =
      rawUnpublishDate && typeof rawUnpublishDate === 'string'
        ? new Date(Date.parse(rawUnpublishDate))
        : rawUnpublishDate;

    const form: AdOptions = {
      text,
      telegramChatId: this.chatId, // TODO: move it to backend
      restaurantId: this.isRestaurantOwner
        ? this.$store.getters.restId
        : restaurantId,
      telegramFileIds: this.telegramFileIds,
    };

    /**
     * Restaurant owners can not set time via time-picker, so we must check
     * if this values are not empty.
     */

    if (publishDate && unpublishDate) {
      publishDate.setHours(
        this.publishContext.hours,
        this.publishContext.minutes,
      );

      unpublishDate.setHours(
        this.unpublishContext.hours,
        this.unpublishContext.minutes,
      );

      form.publishTimestamp = publishDate.getTime();
      form.unpublishTimestamp = unpublishDate.getTime();
    }

    /**
     * If this.id is not empty, then an admin's editing the ad and we need to just PUT
     * new data inside it.
     *
     * If this value is empty, then we just creating new ad via POST request.
     */

    if (this.id) {
      const result = await this.axios.put<IAd>(
        `/advertisements/${this.id}`,
        form,
      );
      this.sending = false;
      this.$router.push('/ads');
    } else {
      try {
        const result = await this.axios.post<IAd>('/advertisements', form);
        if (result instanceof Error) {
          const extraMessage: string =
            (result as any)?.response?.data?.message || result.message;
          // eslint-disable-next-line no-console
          this.$bvModal.msgBoxOk(
            `${this.$t(
              'validation.cantPublishAds',
            ).toString()}: ${extraMessage}`,
            {
              title: 'error',
              variant: 'warning',
            },
          );
          this.sending = false;
        } else {
          this.$router.push('/ads');
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }
}
