
import { Component, Vue } from 'vue-property-decorator';
import { ICity, ICuisine, CitySelectable, RoleEnum } from 'types';

@Component({ name: 'Cuisines' })
export default class Cuisines extends Vue {
  fields = [
    {
      key: 'name',
      label: 'Название',
      sortable: true,
    },
    {
      key: 'name_en',
      label: 'Название (en)',
      sortable: true,
    },
    {
      key: 'position',
      label: 'Позиция в списке',
      sortable: true,
    },
    {
      key: 'enabled',
      label: this.$t('cuisines.enabled'),
      sortable: true,
    },
    {
      key: 'cityId',
      label: 'Город',
    },
  ];

  isAdmin = this.$store.getters.roleId === RoleEnum.ADMIN;
  cuisines = [];
  cities: CitySelectable[] = [];

  async mounted() {
    const cuisinesResponse = await this.axios.get('/cuisines');
    this.cuisines = cuisinesResponse.data;

    if (this.isAdmin) {
      this.cities = await this.axios
        .get<ICity[]>('/cities')
        .then(({ data }) => {
          const citiesFormatted = data.map((city) => ({
            value: city,
            text: city.name,
          }));

          return [...citiesFormatted, { value: null, text: 'Все города' }];
        });
    }
  }

  async onCityChanged(value: ICity) {
    const url = `/cuisines` + (value ? `/city/${value.id}` : '');

    this.cuisines = await this.axios
      .get<ICuisine[]>(url)
      .then(({ data }) => data);
  }
}
