
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ICity,
  ICuisine,
  IDistrict,
  IDistrictRestaurant,
  IRestaurant,
  RoleEnum,
} from 'types';

import PhotoUploader from './PhotoUploader.vue';

type RestaurantResponsed = IRestaurant & {
  notificationUser: { telegramId: string | number };
};

interface RawRestaurant extends Omit<IRestaurant, 'position' | 'minOrderSum'> {
  notificationUser: { telegramId: string | number };
  deliveryPriceInput: string;
  positionInput: string;
  minOrderSumInput: string;
  active: boolean;
}

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

const SCHEDULE_REGEX = /^([0-9]{2}:[0-6][0-9]-[0-9]{2}:[0-6][0-9],?){1,2}$/;

@Component({
  name: 'Restaurant',
  components: { 'photo-uploader': PhotoUploader },
})
export default class Restaurant extends Vue {
  @Prop() id!: number;

  get notificationAccountTelegramId() {
    return this.restaurant.notificationUser?.telegramId;
  }

  set notificationAccountTelegramId(value) {
    this.restaurant.notificationUser = { telegramId: value };
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isRestaurantOwner() {
    return this.$store.getters.roleId === RoleEnum.RESTAURANT_OWNER;
  }

  get isCityAdmin() {
    return [RoleEnum.ADMIN, RoleEnum.CITY_ADMIN].includes(
      this.$store.getters.roleId,
    );
  }

  get isOperator() {
    return [RoleEnum.ADMIN, RoleEnum.CITY_ADMIN, RoleEnum.OPERATOR].includes(
      this.$store.getters.roleId,
    );
  }

  get cityId() {
    return this.$store.getters.cityId;
  }

  /**
   * Variables that are responsible for editting
   * restaurant info
   */
  restaurant: RawRestaurant = {
    id: '',
    name: '',
    name_en: '',
    positionInput: '',
    deliveryPriceInput: '',
    deliveryTime: '',
    enabled: false,
    minOrderSumInput: '',
    canPickup: false,
    canDelivery: false,
    telegramFileId: '',
    schedule: '',
    notificationUser: { telegramId: '' },
    cityId: '',
    city: {
      id: '',
      name: '',
      name_en: '',
      chatname: '',
      currency: '',
    },
    workingDays: [
      { value: 1, enabled: true },
      { value: 2, enabled: true },
      { value: 3, enabled: true },
      { value: 4, enabled: true },
      { value: 5, enabled: true },
      { value: 6, enabled: true },
      { value: 7, enabled: true },
    ],
    link: '',
    maximumPreorderTime: null,
    active: false,
  };

  districts: IDistrict[] = [];
  districtRestaurants: IDistrictRestaurant[] = [];
  districtsSelector: { value: string; text: string }[] = [];
  linkVisible = false;

  photoUploading = false;
  loading = true;

  getLocalizedDayOfWeek(dayOfWeek) {
    switch (dayOfWeek.value) {
      case 1:
        return this.$t('restaurant.monday');
      case 2:
        return this.$t('restaurant.tuesday');
      case 3:
        return this.$t('restaurant.wednesday');
      case 4:
        return this.$t('restaurant.thursday');
      case 5:
        return this.$t('restaurant.friday');
      case 6:
        return this.$t('restaurant.saturday');
      case 7:
        return this.$t('restaurant.sunday');
    }
  }

  /**
   * Variables that are responsible for validation
   */
  errors: {
    [key: string]: string;
  } = {};

  notificationUserNotFound = null;
  get notificationAccountValidation() {
    return this.notificationUserNotFound === null ? null : false;
  }

  get restaurantNameValidation() {
    if (!this.restaurant?.name) return false;

    return (
      this.restaurant.name.length < 256 && this.restaurant.name.length >= 3
    );
  }

  get restaurantNameEnValidation() {
    if (!this.restaurant?.name_en) return false;

    return (
      this.restaurant.name_en.length < 256 &&
      this.restaurant.name_en.length >= 3
    );
  }

  get descriptionValidation() {
    if (!this.restaurant?.description) return null;

    return this.restaurant.description.length < 512;
  }

  get descriptionEnValidation() {
    if (!this.restaurant?.description_en) return null;

    return this.restaurant.description_en.length < 512;
  }

  get receiptTextValidation() {
    if (!this.restaurant?.receipt_text) return null;

    return this.restaurant.receipt_text.length < 128;
  }

  get receiptTextEnValidation() {
    if (!this.restaurant?.receipt_text_en) return null;

    return this.restaurant.receipt_text_en.length < 128;
  }

  get scheduleValidation() {
    if (!this.restaurant.schedule) return false;

    return SCHEDULE_REGEX.test(this.restaurant.schedule);
  }

  get addressValidation() {
    if (!this.restaurant.address) return null;

    return this.restaurant.address.length < 256;
  }

  get deliveryTimeValidation() {
    if (!this.restaurant.deliveryTime) return null;

    return this.restaurant.deliveryTime.length < 256;
  }

  get allValidationHasBeenPassed() {
    return (
      this.restaurantNameValidation !== false &&
      this.restaurantNameEnValidation !== false &&
      this.descriptionValidation !== false &&
      this.descriptionEnValidation !== false &&
      this.scheduleValidation !== false &&
      this.addressValidation !== false &&
      this.deliveryTimeValidation !== false
    );
  }

  /**
   * Variables that are responsible for editting restaurant
   * data that only admins can change
   */
  selectedCuisines: string[] = [];
  selectedCity: ICity = null;
  allCuisines: ICuisine[] = [];
  cities: ICity[] = [];

  async mounted() {
    const restaurant = await this.axios.get('/restaurants/' + this.id);

    this.restaurant = {
      active: false,
      ...(restaurant.data as RestaurantResponsed),
      deliveryPriceInput: restaurant.data.deliveryPrice,
      positionInput: restaurant.data.position,
      minOrderSumInput: restaurant.data.minOrderSum,
    };

    if (this.restaurant.restaurantCuisines) {
      this.selectedCuisines = this.restaurant.restaurantCuisines.map(
        (element) => element.cuisineId,
      );
    }

    this.selectedCity = this.restaurant.city;

    const districts = await this.axios.get<IDistrict[]>(
      `/districts/plain/${this.restaurant.cityId}`,
    );

    this.districts = districts.data;
    this.districtsSelector = this.districts.map((d) => ({
      value: d.id,
      text: `${d.name} (${d.name_en})`,
    }));
    this.districtRestaurants = districts.data.map((district) => {
      return {
        restaurantId: this.restaurant.id,
        districtId: district.id,
        enabled: false,
        price: null,
        district: {
          name: district.name,
          name_en: district.name_en,
        },
      } as IDistrictRestaurant;
    });

    /**
     * To show existing relations between districts and restaurants,
     * we map the existing relations over blank ones that were created
     * two lines before.
     */

    if (this.restaurant.districtRestaurants) {
      const { districtRestaurants } = this.restaurant;

      for (const er of districtRestaurants) {
        const blankRelation = this.districtRestaurants.find(
          (dr) => dr.districtId === er.districtId,
        );

        blankRelation.enabled = er.enabled;
        blankRelation.price = er.price || null;
      }
    }

    const cuisines = await this.axios.get(
      '/cuisines/city/' + this.restaurant.cityId,
    );

    this.allCuisines = cuisines.data;

    this.axios.get('/cities').then((response) => {
      this.cities = response.data.map((city) => ({
        value: city,
        text: city.name,
      }));
    });

    this.loading = false;
  }

  forceUpdate() {
    setTimeout(() => this.$forceUpdate(), 50);
  }

  onDelete() {
    if (confirm(this.$t('restaurant.confirm_delete').toString())) {
      this.axios
        .delete('/restaurants/' + this.id)
        .then(() => this.$router.push({ path: '/restaurants' }));
    }
  }

  onLink() {
    localStorage.setItem(`${storagePrefix}-restaurantId`, this.id.toString());

    this.$router.push('/dishCategories/');
  }

  onReviews() {
    this.$router.push('/restaurants/' + this.restaurant.id + '/reviews');
  }

  onUploading() {
    this.photoUploading = true;
  }

  onUploaded(telegramFileId: string) {
    this.photoUploading = false;
    this.restaurant.telegramFileId = telegramFileId;
  }

  onSubmit(e: Event) {
    this.errors = {};

    // if (this.restaurant.schedule) {
    //     if (
    //         !/^([0-9]{2}:[0-6][0-9]-[0-9]{2}:[0-6][0-9],?){1,2}$/.test(
    //             this.restaurant.schedule
    //         )
    //     ) {
    //         this.errors.schedule = this.$t(
    //             'restaurant.schedule_error'
    //         ).toString()
    //         e.preventDefault()
    //         return false
    //     }
    // }

    // Removing unnecessary fields

    const {
      notificationAccountId,
      restaurantCuisines,
      districtRestaurants: oldDistrictRestaurants,
      city,
      notificationUser,
      deliveryPriceInput,
      minOrderSumInput,
      positionInput,
      ...rawForm
    } = this.restaurant;

    const districtRestaurants = this.districtRestaurants.map((dr) => ({
      ...dr,
      price: dr.price || 0,
    }));

    // Converting data that we need as a form

    const form = {
      ...rawForm,
      deliveryPrice: parseInt(this.restaurant.deliveryPriceInput),
      minOrderSum: parseInt(this.restaurant.minOrderSumInput),
      position: parseInt(this.restaurant.positionInput),
      cityId: this.isCityAdmin ? this.cityId : this.selectedCity.id,
      selectedCuisines: this.selectedCuisines,
      notificationAccountTelegramId: this.notificationAccountTelegramId,
      districtRestaurants:
        this.districts?.length > 0 ? districtRestaurants : null,
    };

    this.axios
      .put<IRestaurant>(`/restaurants/${this.id}`, form)
      .then((response) => {
        if (response instanceof Error) throw response;

        this.$router.push(this.isRestaurantOwner ? '/' : '/restaurants');
      })
      .catch((e) => {
        switch (e.response?.data?.code) {
          case 'FST_KEY_IS_MISSING':
            if (e.response.data.message === 'districtRestaurant.price') {
              this.errors.district = 'error';
            }

            break;
          case 'FST_NOTIFICATION_ACCOUNT_NOT_FOUND':
            this.notificationUserNotFound = true;

            break;
          default:
            break;
        }
      });
  }
}
