
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { EMailingStatus, ICity, IMailingBindedButton } from 'types';

@Component({
  name: 'Mailing',
  computed: mapState(['spamLists']), // Needed for showing the process
})
export default class Mailing extends Vue {
  spamLists: [];
  text = '';
  intervalId = null;
  photoId: string = null;
  lastActiveDate: string = null;
  cities: ICity[] = [];
  cityId: ICity['id'] = null;
  amountOfUsers = 0;

  bindedButton: IMailingBindedButton = {
    enabled: false,
    type: 'url',
    position: 'inline',
    text: '',
    value: '',
  };

  buttonTypeOptions: IMailingBindedButton['type'][] = ['start', 'url'];
  buttonPositionOptions: IMailingBindedButton['position'][] = [
    'inline',
    'bottom',
  ];

  onButtonTypeChanged(newType: IMailingBindedButton['type']) {
    if (this.bindedButton.type === 'url') this.bindedButton.position = 'inline';
  }

  get isAdmin() {
    return this.$store.getters.roleId === 10;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === 30;
  }

  async onLastActiveDateChanged(date: string) {
    this.lastActiveDate = date;

    this.updateCount();
  }

  async onCityChanged(city: ICity) {
    this.cityId = city.id;

    this.updateCount();
  }

  async updateCount() {
    const params: { lastActiveDate?: string; cityId?: string } = {};

    if (this.lastActiveDate) params.lastActiveDate = this.lastActiveDate;
    if (this.cityId) params.cityId = this.cityId;

    const { data } = await this.axios.get<number>('/users/count', {
      params,
    });

    this.amountOfUsers = data;
  }

  getStatus(status: EMailingStatus) {
    switch (status) {
      case EMailingStatus.failed:
        return 'Ошибка';
      case EMailingStatus.created:
        return 'Ждёт';
      case EMailingStatus.processing:
        return 'Идёт';
      case EMailingStatus.finished:
        return 'Закончилась';
    }
  }

  created() {
    // Needed for updating status of spamming

    this.intervalId = setInterval(this.updateSpamStatus, 3000);
  }

  destroy() {
    clearInterval(this.intervalId);
  }

  mounted() {
    if (this.isAdmin) {
      this.axios.get('/cities').then((response) => {
        this.cities = response.data.map((city) => ({
          value: city,
          text: city.name,
        }));
      });
    }

    if (this.isCityAdmin) {
      this.cityId = this.$store.getters.cityId;
    }
  }

  updateSpamStatus() {
    this.$store.dispatch('getSpamStatus');
  }

  onTestSendClick() {
    const telegramId = prompt(
        'Напишите Telegram ID пользователя для отправки тестового сообщения:',
      ),
      form = {
        photoId: this.photoId,
        text: this.text,
        cityId: this.cityId,
        recipientId: telegramId,
        bindedButton: null,
      };

    if (this.bindedButton.enabled) {
      form.bindedButton = this.bindedButton;
    }

    this.axios.post('/spam/sendTestMessage', form);
  }

  onSubmit() {
    const form = {
      photoId: this.photoId,
      text: this.text,
      lastActiveDate: this.lastActiveDate
        ? new Date(Date.parse(this.lastActiveDate))
        : null,
      cityId: this.cityId,
      bindedButton: null,
    };

    if (this.bindedButton.enabled) {
      form.bindedButton = this.bindedButton;
    }

    this.axios.post('/spam', form);
  }
}
