
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IMessage } from 'types';

@Component({ name: 'Chat' })
export default class Chat extends Vue {
  @Prop([Number, String])
  threadId: number | string;

  @Prop(String)
  customerName: string;

  @Prop({ type: Boolean, default: false })
  shown: boolean;

  isLoading = true;
  messages: IMessage[] = [];
  textToSend = '';
  isSending = false;

  updateInterval = null;

  mounted() {
    /**
     * https://github.com/timetoeatio/timetoeatio/issues/370
     */

    const UPDATE_MESSAGE_HISTORY_INTERVAL = 60 * 1000;

    this.updateInterval = setInterval(() => {
      if (this.shown === false) return;

      if (this.$route.path !== '/orders') {
        clearInterval(this.updateInterval);
        return;
      }

      this.updateMessagesList();
    }, UPDATE_MESSAGE_HISTORY_INTERVAL);
  }

  updateMessagesList() {
    this.isLoading = true;
    this.messages.length = 0;

    this.axios.get<IMessage[]>('/orders/' + this.threadId).then(({ data }) => {
      this.messages = data;
      this.isLoading = false;

      // For scrolling down after messages are loaded

      const chatBox = this.$refs.chatbox as Vue;
      //   setImmediate(() => {
      chatBox.$el.scrollTop = chatBox.$el.scrollHeight;
      //   });

      chatBox.$el.querySelector('input')?.focus();
    });
  }

  sendToRestaurant() {
    this.isSending = true;

    this.axios
      .post(`/messages/${this.threadId}/restaurant`, { text: this.textToSend })
      .then(() => {
        this.isSending = false;
        this.textToSend = '';
        this.updateMessagesList();
      });
  }

  onSubmit() {
    this.isSending = true;

    this.axios
      .post('/orders/' + this.threadId, { text: this.textToSend })
      .then(() => {
        this.isSending = false;
        this.textToSend = '';
        this.updateMessagesList();
      });
  }
}
