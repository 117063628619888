
import { Component, Prop, Vue } from 'vue-property-decorator';
import locale from 'date-fns/locale/ru';
import { formatDistanceToNow } from 'date-fns';
import { IAd } from 'types';

type AdItem = IAd & { publishDate: string; unpublishDate: string };

@Component({ name: 'Ads' })
export default class Ads extends Vue {
  rawAds: IAd[] = [];
  timeout = null;

  formatDistanceToNow = formatDistanceToNow;

  get locale() {
    if (this.$i18n.locale === 'ru') return locale;

    return null;
  }

  get isAdmin() {
    return this.$store.getters.roleId === 10;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === 30;
  }

  get isRestaurantOwner() {
    return this.$store.getters.roleId === 20;
  }

  get ads() {
    return this.rawAds
      .slice()
      .sort((a, b) => b.publishDate.getTime() - a.publishDate.getTime());
  }

  get fields() {
    return [
      this.isAdmin || this.isCityAdmin
        ? {
            key: 'restaurant',
            label: this.$t('ad.restaurant', {
              restaurant: '',
            }),
            sortable: true,
          }
        : null,
      {
        key: 'publishDate',
        label: this.$t('ad.publishDate', { publishDate: '' }),
        sortable: true,
      },
      {
        key: 'unpublishDate',
        label: this.$t('ad.unpublishDate', { unpublishDate: '' }),
        sortable: true,
      },
      {
        key: 'status',
        label: this.$t('ad.status'),
        sortable: true,
      },
    ];
  }

  mounted() {
    this.axios.get<AdItem[]>('/advertisements').then(({ data }) => {
      this.rawAds = data.map((item) => ({
        ...item,
        publishDate: new Date(Date.parse(item.publishDate)),
        unpublishDate: new Date(Date.parse(item.unpublishDate)),
      }));
    });

    if (this.isRestaurantOwner) {
      this.axios.get<number>('/advertisements/timeout').then(({ data }) => {
        this.timeout = data;
      });
    }
  }
}
