
import { Component, Prop, Vue } from 'vue-property-decorator';

type ITag = {
  id: string;
  name: string;
  name_en: string;
  cityId: string;
};

type TagModified = ITag & { status: 'SELECTED' | 'UNSELECTED' };

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

@Component({ name: 'TagPad' })
export default class TagPad extends Vue {
  SELECTED = 'SELECTED';
  UNSELECTED = 'UNSELECTED';

  @Prop({ default: () => [] })
  availableTags: ITag[];

  @Prop({ default: () => [] })
  dishTags: ITag[];

  addTag(tag: ITag) {
    const dishAlreadySelected = this.dishTags.find((et) => et.id === tag.id);
    if (dishAlreadySelected) return;

    this.dishTags.push(tag);

    this.$emit('listUpdated', this.dishTags);
  }

  removeTag(tag: ITag) {
    const tagIndex = this.dishTags.findIndex((et) => et.id === tag.id);
    if (tagIndex === -1) return;

    this.dishTags.splice(tagIndex, 1);
    this.$emit('listUpdated', this.dishTags);
  }

  get tags() {
    return this.availableTags.map((tag) => ({
      ...tag,
      status: this.dishTags.some((et) => et.id === tag.id)
        ? this.SELECTED
        : this.UNSELECTED,
    }));
  }

  getClassesBasedOnTag(tag: TagModified): string {
    let tagList = 'tag mr-1 ';
    if (tag?.status === this.UNSELECTED) tagList += 'add';
    else tagList += 'not-selectable';

    return tagList;
  }

  get interfaceLang(): string {
    return localStorage.getItem(`${storagePrefix}-locale`) || 'en';
  }
}
