
import { ICity } from 'types';

import PhotoUploader from './PhotoUploader.vue';

const SCHEDULE_TEST_REGEXP =
  /^([0-9]{2}:[0-6][0-9]-[0-9]{2}:[0-6][0-9],?){1,2}$/;

export default {
  name: 'RestaurantAdd',
  components: { 'photo-uploader': PhotoUploader },
  data() {
    return {
      /**
       * We create a raw form because some fields of it must
       * be converted before sending the requests.
       *
       * The fields are: `position`, `minOrderSum`, `notificationAccountTelegramId`.
       */
      rawForm: {
        name: '',
        name_en: '',
        description: '',
        description_en: '',
        position: null,
        enabled: true,
        minOrderSum: null,
        deliveryTime: null,
        notificationAccountTelegramId: null,
        telegramFileId: null,
        selectedCuisines: [],
        cityId: null,
        address: null,
        canPickup: false,
        canDelivery: false,
      },
      cuisines: [],
      cities: [],
      districts: [],
      deliveryPrices: {},
      deliveryEnabled: {},
      notificationUserNotFound: null,
      notificationUserNotFoundText: '',
      scheduleError: null,
      photoUploading: false,
    };
  },
  computed: {
    roleId() {
      return this.$store.getters.roleId;
    },
    cityId() {
      return this.$store.getters.cityId;
    },
    notificationAccountValidation() {
      return this.notificationUserNotFound === null ? null : false;
    },
    scheduleValidation() {
      return this.scheduleError === null ? null : false;
    },
    scheduleValidationErrorText() {
      return this.$t('restaurant.schedule_error')
        .toString()
        .replaceAll(/<[^>]*>/g, '');
    },
    descriptionEnLengthValidation() {
      return this.rawForm.description_en?.length >= 512 ? false : null;
    },
    descriptionLengthValidation() {
      return this.rawForm.description?.length >= 512 ? false : null;
    },
    hasValidationErrors() {
      return (
        this.descriptionEnLengthValidation === false ||
        this.descriptionLengthValidation === false ||
        this.scheduleValidation === false ||
        this.notificationAccountValidation === false
      );
    },
  },
  mounted() {
    // Gets a list of cities to let the user to select them while creating the restaurant

    this.axios.get('/cities').then(({ data }) => {
      // It needed to be converted in way the bootstrap-vue <b-table> will understand it

      this.cities = (data as ICity[]).map((city) => ({
        value: city.id,
        text: city.name,
      }));
    });

    if (this.cityId) {
      this.rawForm.cityId = this.cityId;

      this.axios.get(`/districts/plain/${this.cityId}`).then((response) => {
        this.districts = response.data;
      });
    }
  },
  methods: {
    onSubmit() {
      if (!SCHEDULE_TEST_REGEXP.test(this.rawForm.schedule)) {
        this.scheduleError = true;

        return;
      }

      this.scheduleError = null;
      this.notificationUserNotFound = null;

      const districtRestaurants = this.districts.map((district) => {
        return {
          districtId: district.id,
          price: this.deliveryPrices[district.id],
          enabled: this.deliveryEnabled[district.id],
        };
      });

      /**
       * Because we take data from input tags, some values of the form
       * are strings by default. This is why we need to convert them
       * separately.
       */
      const form = {
        ...this.rawForm,
        position: parseFloat(this.rawForm.position),
        minOrderSum: parseFloat(this.rawForm.minOrderSum),
        notificationAccountTelegramId: parseFloat(
          this.rawForm.notificationAccountTelegramId,
        ),
        deliveryPrice: parseFloat(this.rawForm.deliveryPrice) || 0,
        cityId: this.roleId === 30 ? this.cityId : this.rawForm.cityId,
        districtRestaurants,
      };

      this.axios
        .post('/restaurants/', form)
        .then((response) => {
          if (response.isAxiosError) throw response;

          /**
           * We send the photo by a seperate request, because the whole relation
           * between the admin panel and API was build on JSON and this would've been
           * quite hard to translate it onto multipart/forms just for sending images.
           *
           * This is why we send it separately - it uses multipart for the request!
           */
          if (response.data?.id && this.file) {
            const photoForm = new FormData();
            photoForm.append('photo', this.file);

            return this.axios.put(
              `/restaurants/${response.data.id}/photo`,
              photoForm,
            );
          }
        })
        .then(() => this.$router.push({ path: '/restaurants' }))
        .catch((e) => {
          if (e.response.data.code === 'FST_NOTIFICATION_ACCOUNT_NOT_FOUND') {
            this.notificationUserNotFound = true;
            this.notificationUserNotFoundText = e.response.data.message;
          }
        });
    },
    onCityChanged() {
      this.axios
        .get(`/districts/plain/${this.rawForm.cityId}`)
        .then((response) => {
          this.districts = response.data;
        });

      this.axios
        .get(`/cuisines/city/${this.rawForm.cityId}`)
        .then((response) => {
          this.cuisines = response.data;
        });
    },
    onUploading() {
      this.photoUploading = true;
    },
    onUploaded(telegramFileId: string) {
      this.photoUploading = false;
      this.rawForm.telegramFileId = telegramFileId;
    },
  },
};
