
import { subDays } from 'date-fns';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ICity, CitySelectable, RoleEnum, UserWithPhoto } from 'types';

@Component({ name: 'UsersTop' })
export default class UsersTop extends Vue {
  loading = true;
  users: UserWithPhoto[] = [];
  cities: CitySelectable[] = [];
  selectedCity: ICity = null;
  startDate: string = subDays(new Date(), 7).toLocaleString();

  async mounted() {
    if (this.isAdmin) {
      const citiesRequest = await this.axios.get<ICity[]>('/cities');

      this.cities = citiesRequest.data.map((city) => ({
        value: city,
        text: city.name,
      }));
    }

    if (this.isCityAdmin) {
      await this.loadUsers();

      await this.loadImages();
    }
  }

  async loadUsers() {
    this.loading = true;

    const usersRequest = await this.axios.get<UserWithPhoto[]>('/users/top', {
      params: {
        cityId: this.cityId,
        startDate: this.startDate,
      },
    });

    this.users = usersRequest.data.map((user, index) => ({
      ...user,
      index,
    }));

    this.loading = false;
  }

  async loadImages() {
    const usersWithSources: UserWithPhoto[] = [];

    for (const user of this.users) {
      try {
        if (!user.photo) throw new Error();

        const response = await this.axios.get(`/photo/`, {
          responseType: 'blob',
          params: {
            cityId: this.cityId,
            telegramFileId: user.photo,
          },
        });

        const imageSource = URL.createObjectURL(response.data);

        usersWithSources.push({ ...user, photo: imageSource });
      } catch {
        usersWithSources.push(user);
      }
    }

    this.users = usersWithSources;
  }

  @Watch('selectedCity')
  async onCitySelected() {
    await this.loadUsers();

    await this.loadImages();
  }

  async onDateSelected() {
    await this.loadUsers();

    await this.loadImages();
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get cityId() {
    if (this.isCityAdmin) return this.$store.getters.cityId;
    return this.selectedCity?.id;
  }
}
