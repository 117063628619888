
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ICity } from 'types';

@Component({
  name: 'Cities',
})
export default class Cities extends Vue {
  private cities: ICity[] = [];
  private fields = [
    {
      key: 'name',
      label: this.$t('city.name'),
      sortable: true,
    },
    {
      key: 'chatname',
      label: this.$t('city.chatname'),
      sortable: true,
    },
    {
      key: 'currency',
      label: this.$t('city.currency'),
      sortable: true,
    },
  ];

  async mounted() {
    const response = await this.axios.get('/cities');
    // if (response.status === 200)
    this.cities = response.data;
  }
}
