
import { Component, Vue } from 'vue-property-decorator';
import { RoleEnum, ICity, ITag } from 'types';

import { PreparedCity } from './Tags.vue';

@Component({ name: 'Tag' })
export default class Tag extends Vue {
  tagId: ITag['id'] = null;
  tag: ITag | Omit<ITag, 'id'> = {
    name: '',
    name_en: '',
    cityId: '',
  };

  city: ICity = null;
  cities: PreparedCity[] = [];

  async mounted() {
    const tagId = this.$route.params.id;

    if (tagId) {
      this.tagId = tagId;

      const tagResponse = await this.axios.get<ITag>('/tags/' + tagId);
      this.tag = tagResponse.data;
    }

    if (this.isAdmin) {
      const citiesResponse = await this.axios.get<ICity[]>('/cities');
      this.cities = citiesResponse.data?.map((city) => ({
        text: city.name,
        value: city,
      }));
    }
  }

  async onSubmit() {
    const body = {
      ...this.tag,
      cityId: this.isCityAdmin ? this.cityId : this.city?.id,
    };

    if (this.tagId) {
      await this.axios.put('/tags/' + this.tagId, body);
    } else {
      await this.axios.post('/tags', body);
    }

    await this.$router.push('/tags');
  }

  // Getters from the store

  get cityId() {
    return this.$store.getters.cityId;
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }
}
