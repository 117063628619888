
import { Component, Vue } from 'vue-property-decorator';
import { ICity, IReview, RoleEnum } from 'types';

interface ReviewFormatted extends IReview {
  createdAtString: string;
  approving: boolean;
  deleting: boolean;
  answering: boolean;
  answerShown: boolean;
  answerText: string;
  telegramProfile?: {
    name: string;
    username: string;
  };
}

type PreparedCity = { text: string; value: ICity };

@Component({ name: 'Reviews' })
export default class Reviews extends Vue {
  reviews: ReviewFormatted[] = [];
  cities: PreparedCity[] = [];
  selectedCity: ICity = null;

  imageModalShown = false;
  imageSource: string = null;

  answerText = '';

  async updateReviewList() {
    if (!this.cityId && !this.restaurantId) return null;

    const reviewResponse = await this.axios.get<IReview[]>(
      '/reviews/' +
        (this.restaurantId ? `restaurant/${this.restaurantId}` : this.cityId),
    );

    this.reviews = reviewResponse.data?.map((review: IReview) => {
      return {
        ...review,
        createdAtString: new Date(review.createdAt).toLocaleString('ru'),
        approving: false,
        answering: false,
        deleting: false,
        answerShown: Boolean(review.answer),
        answerText: review.answer,
      };
    });
  }

  async mounted() {
    this.updateReviewList();

    if (this.isAdmin) {
      const citiesResponse = await this.axios.get<ICity[]>('/cities');
      this.cities = citiesResponse?.data?.map((city) => ({
        text: city.name,
        value: city,
      }));
    }
  }

  async onCitySelected(city: ICity) {
    this.selectedCity = city;
    this.updateReviewList();
  }

  async onPublish(review: ReviewFormatted) {
    review.approving = true;
    await this.axios.post('/reviews/' + review.id, { publish: true });

    await this.updateReviewList();
  }

  async onApproved(review: ReviewFormatted) {
    review.approving = true;
    await this.axios.post('/reviews/' + review.id);

    await this.updateReviewList();
  }

  async onDelete(review: ReviewFormatted) {
    review.deleting = true;
    await this.axios.delete('/reviews/' + review.id);

    await this.updateReviewList();
  }

  async onAnswer(review: ReviewFormatted) {
    review.answering = true;
    await this.axios.put('/reviews/' + review.id, {
      text: review.answerText,
    });

    await this.updateReviewList();
  }

  async loadImage(review: ReviewFormatted) {
    this.imageSource = null;

    const response = await this.axios.get(`/photo/`, {
      responseType: 'blob',
      params: {
        cityId: this.cityId,
        telegramFileId: review.telegramFileId,
      },
    });

    this.imageSource = URL.createObjectURL(response.data);
    this.imageModalShown = true;
  }

  getRatingEmojied(review: IReview): string {
    const length = review.rating > 0 ? review.rating : 0;

    return new Array(length).fill('⭐').join('');
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get isRestaurantOwner() {
    return this.$store.getters.roleId === RoleEnum.RESTAURANT_OWNER;
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }

  get restaurantId() {
    return this.$route.params?.id;
  }

  get cityId() {
    if (this.isCityAdmin || this.isOperator) return this.$store.getters.cityId;
    if (this.isAdmin) return this.selectedCity?.id;
    if (this.isRestaurantOwner) return this.$store.state.restaurant?.cityId;

    return null;
  }
}
