
import { Component, Vue } from 'vue-property-decorator';
import { IDish, RoleEnum } from 'types';
import { formatDistance, formatDistanceToNow } from 'date-fns';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

@Component({ name: 'Dishes' })
export default class Dishes extends Vue {
  public dishes: IDish[] = [];

  public async changeState(dish: IDish) {
    const response = await this.axios.put<IDish>(`/dishes/${dish.id}`, {
      enabled: dish.enabled,
    });

    dish = response.data;
  }

  async mounted() {
    const response = await this.axios.get<IDish[]>(
      '/dishes?dishCategoryId=' +
        localStorage.getItem(`${storagePrefix}-dishCategoryId`),
    );

    this.dishes = response.data.sort((a, b) => {
      if (a.position > b.position) return 1;
      if (a.position < b.position) return -1;

      return a.name.localeCompare(b.name);
    });
  }

  get interfaceLang(): string {
    return localStorage.getItem(`${storagePrefix}-locale`) || 'en';
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }

  inWords(date: Date) {
    return formatDistanceToNow(date, { addSuffix: true });
  }
}
