
import { Component, Vue } from 'vue-property-decorator';
import { IDistrict } from 'types';

@Component({
  name: 'District',
})
export default class District extends Vue {
  district: IDistrict = {
    id: '',
    name: '',
    name_en: '',
    cityId: '',
  };

  loading = true;

  get cityId(): string {
    return this.$route.params.cityId;
  }

  get districtId(): string {
    return this.$route.params.id;
  }

  async mounted() {
    if (this.districtId) {
      const response = await this.axios.get(`/districts/${this.districtId}`);

      this.district = response.data;
      this.loading = false;
    }
  }

  async onSave() {
    const response = await this.axios.put(
      `/districts/${this.districtId}`,
      this.district,
    );

    this.$router.push(`/city/${this.cityId}/districts`);
  }

  async onAdd() {
    const response = await this.axios.post('/districts/', {
      cityId: this.cityId,
      name: this.district.name,
      name_en: this.district.name_en,
    });

    this.$router.push(`/city/${this.cityId}/districts`);
  }

  async onDelete() {
    const response = await this.axios.delete(`/districts/${this.districtId}`);

    this.$router.push(`/city/${this.cityId}/districts`);
  }
}
