
import { Component, Vue } from 'vue-property-decorator';
import { RoleEnum, ICity, ICuisine } from 'types';

type CityListWrapped = { value: ICity['id']; text: ICity['name'] };

@Component({ name: 'CuisineAdd' })
export default class CuisineAdd extends Vue {
  form: ICuisine = {
    name: null,
    name_en: null,
    position: null,
    enabled: true,
    cityId: null,
  };

  cities: CityListWrapped[] = [];

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get cityId() {
    return this.$store.getters.cityId;
  }

  async mounted() {
    /**
     * Superadmins allowed to choose a city where they want to create cuisine in.
     *
     * If this section was requested by a city admin, it will allow to create a cuisine
     * in the admin's city.
     */
    if (this.isAdmin) {
      const response = await this.axios.get<ICity[]>('/cities');

      this.cities = response.data.map((city) => ({
        value: city.id,
        text: city.name,
      }));
    } else {
      this.form.cityId = this.cityId;
    }
  }

  onSubmit() {
    this.axios
      .post('/cuisines/', this.form)
      .then(() => this.$router.push({ path: '/cuisines' }));
  }
}
