
import { Component, Vue } from 'vue-property-decorator';
import VueMultiselect from 'vue-multiselect';
import { RoleEnum } from 'types';

@Component({
  name: 'UserAdd',
  components: {
    multiselect: VueMultiselect,
  },
})
export default class UserAdd extends Vue {
  form = {
    roleId: this.isCityAdmin ? RoleEnum.RESTAURANT_OWNER : RoleEnum.ADMIN,
    cityId: this.isCityAdmin ? this.cityId : null,
    restaurantId: null,
    restaurant: null,
    password: '',
    username: '',
  };

  restaurants = [];
  cities = [];

  get roles() {
    if (this.isCityAdmin) {
      return [
        {
          value: RoleEnum.RESTAURANT_OWNER,
          text: 'Владелец ресторана 🍲',
        },
        {
          value: RoleEnum.OPERATOR,
          text: 'Оператор ☎️',
        },
      ];
    }

    if (this.isAdmin)
      return [
        { value: RoleEnum.ADMIN, text: 'Главный администратор 👑' },
        { value: RoleEnum.CITY_ADMIN, text: 'Администратор города 🏠' },
        {
          value: RoleEnum.RESTAURANT_OWNER,
          text: 'Владелец ресторана 🍲',
        },
        { value: RoleEnum.OPERATOR, text: 'Оператор ☎️' },
      ];

    return [];
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get cityId() {
    return this.$store.getters.cityId;
  }

  get isShowCity() {
    return (
      this.isAdmin &&
      [RoleEnum.OPERATOR, RoleEnum.CITY_ADMIN].includes(this.form.roleId)
    );
  }

  mounted() {
    this.axios.get('/restaurants').then(({ data }) => {
      /**
       * After getting the list of restaurant, it must convert it to
       * the format that the Bootstrap tables recognize.
       */

      this.restaurants = data;
    });

    /**
     * City admins can only create another admins and no one more.
     *
     * Therefore, they won't need the list of cities.
     */
    if (!this.isCityAdmin) {
      this.axios.get('/cities').then(({ data }) => {
        /**
         * After getting the list of cities, it must convert it to
         * the format that the Bootstrap tables recognize.
         */

        this.cities = data.map((city) => ({
          value: city.id,
          text: city.name,
        }));
      });
    }
  }

  onSubmit() {
    this.axios
      .post('/users/', this.form)
      .then(() => this.$router.push({ path: '/users' }));
  }
}
