
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoleEnum, ICity } from 'types';

import PhotoUploader from './PhotoUploader.vue';

type IConfig = { [key: string]: string | number | boolean };

@Component({
  name: 'City',
  components: { 'photo-uploader': PhotoUploader },
})
export default class City extends Vue {
  @Prop() id!: number;

  // Config params

  config: IConfig = {
    notifyChannelId: null,
    reviewChannelId: null,
    adChatId: null,
    adChatDelay: null,
    adChatGap: null,
    defaultImageId: null,
    showOpenNow: false,
    showTopTen: false,
    showInfoButton: false,
    infoButtonText: null,
    infoButtonTextEn: null,
    infoButtonContent: null,
    infoButtonContentEn: null,
    timeShift: null,
    deliveryMultiplier: null,
    topInterval: null,
    deliveryCostJump: null,
    deliveryCostJumpStart: null,
    deliveryCostJumpEnd: null,
    deliveryCostJumpAmount: null,
  };

  // Needed for form

  isCityLoaded = false;
  isPhotoLoading = false;
  city: ICity = {
    id: null,
    name: '',
    name_en: '',
    chatname: '',
    currency: '',
  };

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get authKey() {
    return this.$store.getters.authKey;
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }

  get muteTopSettings() {
    return !this.config.showTopTen || this.config.showTopTen === 'false';
  }

  get muteInfoButtonSettings() {
    return (
      !this.config.showInfoButton || this.config.showInfoButton === 'false'
    );
  }

  get muteDeliveryJumpSettings() {
    return (
      !this.config.deliveryCostJump || this.config.deliveryCostJump === 'false'
    );
  }

  async mounted() {
    /*
     * Since a single component is responsible for creating a new model and
     * updating it, there's a logic to separate these two keys.
     *
     * If user's editting the city, it will request city details, otherwise
     * it will stop the further code from here.
     */

    if (!this.id) return;

    // Gets city details

    const { data: city } = await this.axios.get(`/cities/${this.id}`);
    this.city = city;
    this.isCityLoaded = true;

    // Gets config rows of this city

    const { data: configRows } = await this.axios.get<IConfig[]>(
      `/config/${this.id}`,
    );

    // Converts list of rows into an object

    configRows.map(({ key, value }) => (this.config[key as string] = value));
  }

  onUploading() {
    this.isPhotoLoading = true;
  }

  onUploaded(telegramFileId: string) {
    this.isPhotoLoading = false;
    this.config.defaultImageId = telegramFileId;
  }

  async onSave() {
    await this.axios.put(`/cities/${this.id}`, this.city);

    // We'll send only those rows that were changed

    const changedRowsOfConfig = Object.fromEntries(
      Object.entries(this.config).map(([key, value]) => [key, value ?? '']),
    );

    this.axios.put(`/config/${this.id}`, changedRowsOfConfig);

    if (this.isAdmin) this.$router.push('/cities');
    if (this.isCityAdmin || this.isOperator) this.$router.push('/');
  }

  async onAdd() {
    await this.axios.post('/cities/', this.city);

    if (this.isAdmin) this.$router.push('/cities');
    if (this.isCityAdmin) this.$router.push('/');
  }
}
