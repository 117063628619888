
import { Component, Vue } from 'vue-property-decorator';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

@Component({ name: 'DishCategoryAdd' })
export default class DishCategoryAdd extends Vue {
  form = {
    name: '',
    name_en: '',
    position: '',
    startDisplayingAt: '',
    stopDisplayingAt: '',
    restaurantId: null,
  };

  async onSubmit() {
    const form = this.form;

    form.restaurantId = localStorage.getItem(`${storagePrefix}-restaurantId`);

    // Removing seconds from time

    if (form.startDisplayingAt) {
      form.startDisplayingAt = form.startDisplayingAt.slice(0, 5);
    }

    if (form.stopDisplayingAt) {
      form.stopDisplayingAt = form.stopDisplayingAt.slice(0, 5);
    }

    // Sending the request

    await this.axios.post('/dishCategories/', form);

    await this.$router.push({ path: '/dishCategories' });
  }

  /** Here goes validation */

  get nameValidation() {
    if (!this.form?.name) return null;

    return this.form.name.length < 256;
  }

  get nameEnValidation() {
    if (!this.form?.name_en) return null;

    return this.form.name_en.length < 256;
  }

  get allValidationHasBeenPassed() {
    return this.nameValidation !== false && this.nameEnValidation !== false;
  }
}
