
import { Component, Vue } from 'vue-property-decorator';
import { IBot, ICity } from 'types';

@Component({ name: 'Bots' })
export default class BotsAdd extends Vue {
  cities: ICity[] = [];
  bot: IBot & { city: Partial<ICity>; id?: string } = {
    botToken: '',
    endpoint: '',
    cityId: '',
    city: { name: '', name_en: '' },
  };

  mounted() {
    this.axios.get('/cities/for_bots').then(({ data }) => {
      this.cities = data.map((city) => ({
        value: city,
        text: city.name,
      }));
    });
  }

  onSubmit() {
    // Removing unnecessary fields from the form

    const { id, city, ...formData } = this.bot;

    // Updates the city_id (by default only bot.city value changes)

    formData.cityId = city.id;

    // Sends the request and returns the admin back to the bot menu

    this.axios
      .post('/bots', formData)
      .then(() => this.$router.push({ path: '/bots' }));
  }
}
