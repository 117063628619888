
import { format } from 'date-fns';
import * as _ from 'lodash';
import VueMultiselect from 'vue-multiselect';
import { Component, Vue } from 'vue-property-decorator';
import {
  ICity,
  IOrder,
  IRestaurant,
  RoleEnum,
  EOrderStatus,
  ORDER_STATUSES_COLORS,
} from 'types';

import ChatModal from './Chat.vue';
import OrderStatusEditor from './OrderStatusEditor.vue';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

export interface PreparedOrder extends Omit<IOrder, 'status'> {
  currency: string;
  status: string;
  chatShown: boolean;
  colors: {
    expandedColorStyle: string;
    collapsedColorStyle: string;
    statusBadgeColor: string;
    // headerStyle: string
  };
}

@Component({
  name: 'Order',
  components: {
    multiselect: VueMultiselect,
    chat: ChatModal,
    'order-status': OrderStatusEditor,
  },
})
export default class Order extends Vue {
  // Fields for setting loaders

  loading = false;
  exporting = false;

  // For filtering of orders

  orders: PreparedOrder[] = [];
  showNumbers = false;
  restaurants: IRestaurant[] = [];
  cities: { value: ICity; text: string }[] = [];
  selectedCity: ICity = null;
  filter = {
    from: new Date(),
    to: new Date(),
    restaurants: [],
  };

  // Needed for showing the chat

  customerName = '';

  // Needed for exporting to Excel

  locale = localStorage.getItem(`${storagePrefix}-locale`);

  // Needed for changing the appearance of the order list

  shortList = false;

  async onStatusChanged({
    orderId,
    status,
  }: {
    orderId: IOrder['id'];
    status: EOrderStatus;
  }) {
    await this.axios.put('/orders/' + orderId, { status });

    await this.onSubmit();
  }

  getOrders(): PreparedOrder[] {
    return this.orders.slice().sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1;
      }

      if (a.createdAt > b.createdAt) {
        return -1;
      }

      return 0;
    });
  }

  getRestaurants(): IRestaurant[] {
    return this.restaurants.slice();
  }

  showPaymentData(order: IOrder) {
    const adminHasRights = Boolean(this.isAdmin || this.isCityAdmin),
      hasReceipt = Boolean(order.paymentReceipt);

    return adminHasRights && hasReceipt;
  }

  get sum(): number {
    return this.orders.reduce(
      (sum, order) => sum + order.sum + order.deliveryCost,
      0,
    );
  }

  get sum_without_delivery(): number {
    return this.orders.reduce((sum, order) => sum + order.sum, 0);
  }

  get count(): number {
    return this.orders.length || 0;
  }

  get avgCheque(): string {
    if (this.count < 1) return '0';

    return (this.sum / this.count).toFixed(2);
  }

  get cityId() {
    return this.$store.getters.cityId;
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }

  get isRestaurantOwner() {
    return this.$store.getters.roleId === RoleEnum.RESTAURANT_OWNER;
  }

  get restaurantId() {
    return this.$store.getters.restId;
  }

  get sums(): string {
    if (this.orders.length < 1) return '';

    const ordersByCity = _.groupBy(
      this.orders,
      (order) => order.restaurant.cityId,
    );

    const sums: { [key: ICity['currency']]: number } = _.reduce(
      ordersByCity,
      (result: { [key: ICity['currency']]: number }, orders, cityId) => {
        const city: ICity = this.cities.find(
          (city) => city.value.id === cityId,
        )?.value;

        result[city.currency] = orders.reduce(
          (acc, order) => acc + order.sum + order.deliveryCost,
          0,
        );

        return result;
      },
      {},
    );

    const sumsStringified = _.map(
      sums,
      (value: number, key: string) => value + ' ' + key,
    ) as string[];

    return _.join(sumsStringified, ', ') as string;
  }

  get sumsWithoutDelivery(): string {
    if (this.orders.length < 1) return '';

    const ordersByCity = _.groupBy(
      this.orders,
      (order) => order.restaurant.cityId,
    );

    const sums: { [key: ICity['currency']]: number } = _.reduce(
      ordersByCity,
      (
        result: { [key: ICity['currency']]: number },
        orders,
        cityId: ICity['id'],
      ) => {
        const city: ICity = this.cities.find(
          (city) => city.value.id === cityId,
        )?.value;

        result[city.currency] = orders.reduce(
          (acc, order) => acc + order.sum,
          0,
        );

        return result;
      },
      {},
    );

    const sumsStringified = _.map(
      sums,
      (value: number, key: string) => value + ' ' + key,
    ) as string[];

    return _.join(sumsStringified, ', ') as string;
  }

  cleanAddress(userId: string) {
    this.axios
      .put(`/users/${userId}/address`, { address: '' })
      .then(() => this.onSubmit());
  }

  /**
   * Prepares orders, providing them currencies of their
   * sums and a human-readable status
   */
  prepareOrders(orders: IOrder[]): PreparedOrder[] {
    return orders.map((order: IOrder) => {
      const statusColor = ORDER_STATUSES_COLORS[order.status] || '#666666';

      const currency = order.restaurant?.city?.currency,
        key: string = EOrderStatus[order.status] || 'noStatus',
        status: string = this.$t('orders.statuses.' + key).toString(),
        chatShown = false;

      const // colorStyle = `background-color: ${statusColor}4e;`,
        // headerStyle = `background-color: ${statusColor}8e;`,
        expandedColorStyle = `border-left: 5px solid ${statusColor}`,
        collapsedColorStyle = `border-top: 5px solid ${statusColor}`,
        statusBadgeColor = `background-color: ${statusColor}`;

      return {
        ...order,
        currency,
        status,
        colors: {
          expandedColorStyle,
          statusBadgeColor,
          collapsedColorStyle,
        },
        // headerStyle,
        chatShown,
      };
    });
  }

  mounted() {
    // Ensures showing orders on the section is only visited

    let ordersRequest: Promise<any>;

    switch (this.$store.getters.roleId) {
      case RoleEnum.ADMIN:
        ordersRequest = this.axios.get<IOrder[]>('/orders');

        break;
      case RoleEnum.CITY_ADMIN:
      case RoleEnum.OPERATOR:
        ordersRequest = this.axios.get<IOrder[]>('/orders', {
          params: { cityId: this.cityId },
        });

        break;
      case RoleEnum.RESTAURANT_OWNER:
        ordersRequest = this.axios.get<IOrder[]>('/orders', {
          params: { rests: [this.restaurantId] },
        });

        break;
    }

    ordersRequest.then(({ data }) => {
      this.orders = this.prepareOrders(data);
    });

    if (this.isAdmin) {
      this.axios.get('/restaurants').then(({ data }) => {
        this.restaurants = data;
      });

      this.axios.get<ICity[]>('/cities').then(({ data }) => {
        this.cities = data.map((city) => ({
          value: city,
          text: city.name,
        }));
      });
    } else if (this.isCityAdmin) {
      this.axios.get('/restaurants/city/' + this.cityId).then(({ data }) => {
        this.restaurants = data;
      });
    }
  }

  exportExcel() {
    const restaurantIds = this.isRestaurantOwner
      ? [this.restaurantId]
      : this.filter.restaurants.map((rest) => rest.id);

    this.exporting = true;

    this.axios
      .get('/orders/export/excel', {
        params: {
          rests: restaurantIds,
          from: this.filter.from,
          to: this.filter.to,
          cityId: this.selectedCity?.id,
          locale: this.locale,
        },
        responseType: 'arraybuffer',
      })
      .then((response) => {
        this.downloadFile(response);
        this.exporting = false;
      });
  }

  downloadFile(file) {
    const { headers, data } = file,
      contentDisposition = headers['content-disposition'],
      contentType = headers['content-type'];

    let filename = 'orders' + format(new Date(), 'yyMMddHHmmss') + '.xlsx';

    if (contentDisposition) {
      if (/=utf-8/.test(contentDisposition)) {
        [, filename] = contentDisposition.match(/filename\*=utf-8''(.*)/);
      } else {
        [, filename] = contentDisposition.match(/filename="?([^"]+)"?/);
      }
    }

    const url = window.URL.createObjectURL(
      new Blob([data], { type: contentType }),
    );
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', decodeURI(filename));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return { filename };
  }

  onSubmit() {
    const restaurantIds = this.isRestaurantOwner
      ? [this.restaurantId]
      : this.filter.restaurants.map((rest) => rest.id);

    this.loading = true;

    this.axios
      .get('/orders', {
        params: {
          rests: restaurantIds,
          from: this.filter.from,
          to: this.filter.to,
          cityId: this.selectedCity?.id,
        },
      })
      .then((response) => {
        this.orders = this.prepareOrders(response.data);
        this.loading = false;
      });
  }
}
