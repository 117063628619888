
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoleEnum } from 'types';

@Component({ name: 'User' })
export default class User extends Vue {
  @Prop() id!: string;

  password = '';
  user = null;
  restaurants = [];
  cities = [];
  roles = [
    { value: 10, text: 'Главный администратор 👑' },
    { value: 30, text: 'Администратор города 🏠' },
    { value: 20, text: 'Владелец ресторана 🍲' },
    { value: 40, text: 'Оператор 🍽' },
  ];

  get isCityAdmin() {
    return [RoleEnum.CITY_ADMIN, RoleEnum.OPERATOR].includes(
      this.$store.getters.roleId,
    );
  }

  mounted() {
    this.axios.get('/users/' + this.id).then((response) => {
      this.user = response.data;
    });

    this.axios.get('/restaurants').then((response) => {
      this.restaurants = response.data.map((rest) => ({
        value: rest.id,
        text: rest.name,
      }));
    });

    /**
     * City admins can't edit city of an admin, therefore they
     * won't need the list of cities.
     */
    if (!this.isCityAdmin) {
      this.axios.get('/cities').then(({ data }) => {
        this.cities = data.map((city) => ({
          value: city.id,
          text: city.name,
        }));
      });
    }
  }

  onDelete() {
    this.axios
      .delete('/users/' + this.id)
      .then(() => this.$router.push({ path: '/users' }));
  }

  onSubmit() {
    const form = {
      ...this.user,
      password: this.password,
    };

    this.axios
      .put('/users/' + this.id, form)
      .then(() => this.$router.push({ path: '/users' }));
  }
}
