
import { Component, Vue } from 'vue-property-decorator';
import { IBot } from 'types';

type BotsFields = { key: keyof IBot | 'city' | 'orderNumber'; label: string };

@Component({ name: 'Bots' })
export default class Bots extends Vue {
  fields: BotsFields[] = [
    { key: 'orderNumber', label: '№' },
    { key: 'city', label: 'City' },
    { key: 'botToken', label: 'Settings' },
  ];

  showNotifyMenu = false;

  notifyText = '';
  notifyTextEn = '';
  bots: (IBot & { orderNumber: number })[] = [];

  async mounted() {
    const { data } = await this.axios.get<IBot[]>('/bots');

    this.bots = data.map((bot, index) => ({
      ...bot,
      orderNumber: index + 1,
    }));
  }
}
