
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoleEnum, IDishCat } from 'types';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

@Component({ name: 'DishCategory' })
export default class DishCategory extends Vue {
  @Prop()
  id: string;

  deleteLinkHovered = false;
  onDeleteHover(value: boolean) {
    this.deleteLinkHovered = value;
  }

  category: IDishCat = null;

  async mounted() {
    const response = await this.axios.get('/dishCategories/' + this.id);
    this.category = response.data;
  }

  public onDishesLinkPressed(category: IDishCat) {
    localStorage.setItem(`${storagePrefix}-dishCategoryId`, category.id);
    this.$router.push('/dishes');
  }

  async onDelete() {
    if (confirm(this.$t('dishCategory.confirm_delete').toString())) {
      await this.axios.delete('/dishCategories/' + this.id);
      this.$router.push('/dishCategories');
    }
  }

  onLink() {
    localStorage.setItem(`${storagePrefix}-dishCategoryId`, this.category.id);
    this.$router.push('/dishes');
  }

  async onSubmit() {
    // Removing seconds from time

    if (this.category.startDisplayingAt) {
      this.category.startDisplayingAt = this.category.startDisplayingAt.slice(
        0,
        5,
      );
    }

    if (this.category.stopDisplayingAt) {
      this.category.stopDisplayingAt = this.category.stopDisplayingAt.slice(
        0,
        5,
      );
    }

    // Sending the updated data

    await this.axios.put('/dishCategories/' + this.id, this.category);

    // Going back

    this.$router.push('/dishCategories');
  }

  /** Here goes validation */

  get nameValidation() {
    if (!this.category?.name) return null;

    return this.category.name.length < 256;
  }

  get nameEnValidation() {
    if (!this.category?.name_en) return null;

    return this.category.name_en.length < 256;
  }

  get allValidationHasBeenPassed() {
    return this.nameValidation !== false && this.nameEnValidation !== false;
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }
}
