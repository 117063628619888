
import { Component, Vue } from 'vue-property-decorator';
import { IBot, ICity } from 'types';

@Component({ name: 'Bots' })
export default class Bots extends Vue {
  cities: ICity[] = [];
  bot: IBot & { city: Partial<ICity>; id?: string } = {
    botToken: '',
    endpoint: '',
    cityId: '',
    city: { name: '', name_en: '' },
  };

  async mounted() {
    await this.axios.get(`/bots/${this.$route.params.id}`).then(({ data }) => {
      this.bot = { ...this.bot, ...data };

      this.axios.get('/cities/for_bots/' + this.bot.id).then(({ data }) => {
        this.cities = data.map((city) => ({
          value: city,
          text: city.name,
        }));
      });
    });
  }

  onSubmit() {
    // Removing unnecessary fields from the form

    const { id, city, ...botData } = this.bot;

    // Updates the city_id (by default only bot.city value changes)

    botData.cityId = city.id;

    this.axios
      .put(`/bots/${this.$route.params.id}`, botData)
      .then(() => this.$router.push({ path: '/bots' }));
  }

  onDelete() {
    this.axios
      .delete(`/bots/${this.$route.params.id}`)
      .then(() => this.$router.push({ path: '/bots' }));
  }
}
