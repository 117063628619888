
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoleEnum, ICity, ICuisine } from 'types';

type CityListWrapped = { value: ICity['id']; text: ICity['name'] };

@Component({ name: 'Cuisine' })
export default class Cuisine extends Vue {
  @Prop() private id!: string;

  form: ICuisine = {
    id: this.id,
    name: null,
    name_en: null,
    position: null,
    enabled: true,
    cityId: null,
    city: null,
  };

  loaded = false;
  cities: CityListWrapped[] = [];

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get hasCity() {
    return this.form.city;
  }

  async mounted() {
    const { data: form } = await this.axios.get<ICuisine>(
      '/cuisines/' + this.id,
    );

    this.form = form;
    this.loaded = true;
  }

  public onDelete() {
    this.axios
      .delete('/cuisines/' + this.id)
      .then(() => this.$router.push({ path: '/cuisines' }));
  }

  public onSubmit() {
    this.axios
      .put('/cuisines/' + this.id, this.form)
      .then(() => this.$router.push({ path: '/cuisines' }));
  }
}
