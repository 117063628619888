
import { Component, Vue } from 'vue-property-decorator';
import { RoleEnum, IDishCat } from 'types';
import { formatDistanceToNow } from 'date-fns';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

@Component({
  name: 'DishCategories',
})
export default class DishCategories extends Vue {
  public dishCategories: IDishCat[] = [];

  public async changeState(dishCategory: IDishCat) {
    const response = await this.axios.put<IDishCat>(
      `/dishCategories/${dishCategory.id}`,
      dishCategory,
    );

    dishCategory = response.data;
  }

  async mounted() {
    const response = await this.axios.get<IDishCat[]>(
      '/dishCategories?restaurantId=' +
        localStorage.getItem(`${storagePrefix}-restaurantId`),
    );

    this.dishCategories = response.data.sort((a, b) => {
      if (a.position > b.position) return 1;

      if (a.position < b.position) return -1;

      return a.name.localeCompare(b.name);
    });
  }

  public hasDisplayingInterval(category: IDishCat): boolean {
    if (category.startDisplayingAt && category.stopDisplayingAt) {
      return true;
    }

    return false;
  }

  public onDishesLinkPressed(category: IDishCat) {
    localStorage.setItem(`${storagePrefix}-dishCategoryId`, category.id);
    this.$router.push('/dishes');
  }

  get interfaceLang(): string {
    return localStorage.getItem(`${storagePrefix}-locale`) || 'en';
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }

  inWords(date: Date) {
    return formatDistanceToNow(date, { addSuffix: true });
  }
}
