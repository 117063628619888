import { RouteConfig } from 'vue-router';

import Login from '../components/Login.vue';
import Logout from '../components/Logout.vue';
import Users from '../components/Users.vue';
import User from '../components/User.vue';
import UserAdd from '../components/UserAdd.vue';
import UsersTop from '../components/UsersTop.vue';

export const userRoutes: Array<RouteConfig> = [
  {
    // title: prefix + "Login",
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    // title: prefix + "Language selector",
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users/add',
    name: 'UserAdd',
    component: UserAdd,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users/top',
    name: 'UsersTop',
    component: UsersTop,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users/:id',
    name: 'User',
    component: User,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
];
