
const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;
export default {
  name: 'Home',
  data() {
    return { langs: ['ru', 'en'] };
  },
  methods: {
    setRussian() {
      localStorage.setItem(`${storagePrefix}-locale`, 'ru');
      this.$i18n.locale = 'ru';
      return this.axios.get(`/language/checkout-locale/ru`);
    },
    setEnglish() {
      localStorage.setItem(`${storagePrefix}-locale`, 'en');
      this.$i18n.locale = 'en';
      return this.axios.get(`/language/checkout-locale/en`);
    },
  },
};
