"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EBotMessageType = exports.EBotMessageStatus = exports.EMailingStatus = exports.EventType = exports.EventStatus = exports.EMessageStatus = exports.EOrderStatus = exports.EPaymentMethod = exports.ERatingState = exports.EAdStatus = exports.RoleEnum = exports.LOCALES = void 0;
var LOCALES;
(function (LOCALES) {
    LOCALES["EN"] = "en";
    LOCALES["RU"] = "ru";
})(LOCALES = exports.LOCALES || (exports.LOCALES = {}));
var RoleEnum;
(function (RoleEnum) {
    RoleEnum[RoleEnum["USER"] = 0] = "USER";
    RoleEnum[RoleEnum["ADMIN"] = 10] = "ADMIN";
    RoleEnum[RoleEnum["RESTAURANT_OWNER"] = 20] = "RESTAURANT_OWNER";
    RoleEnum[RoleEnum["CITY_ADMIN"] = 30] = "CITY_ADMIN";
    RoleEnum[RoleEnum["OPERATOR"] = 40] = "OPERATOR";
})(RoleEnum = exports.RoleEnum || (exports.RoleEnum = {}));
var EAdStatus;
(function (EAdStatus) {
    EAdStatus[EAdStatus["created"] = 0] = "created";
    EAdStatus[EAdStatus["published"] = 10] = "published";
    EAdStatus[EAdStatus["finished"] = 20] = "finished";
    EAdStatus[EAdStatus["publishFailed"] = 30] = "publishFailed";
    EAdStatus[EAdStatus["unpublishFailed"] = 40] = "unpublishFailed";
    EAdStatus[EAdStatus["waitingForImage"] = 50] = "waitingForImage";
})(EAdStatus = exports.EAdStatus || (exports.EAdStatus = {}));
var ERatingState;
(function (ERatingState) {
    ERatingState[ERatingState["unsended"] = -1] = "unsended";
    ERatingState[ERatingState["unrated"] = 0] = "unrated";
    ERatingState[ERatingState["level1"] = 1] = "level1";
    ERatingState[ERatingState["level2"] = 2] = "level2";
    ERatingState[ERatingState["level3"] = 3] = "level3";
    ERatingState[ERatingState["level4"] = 4] = "level4";
    ERatingState[ERatingState["level5"] = 5] = "level5";
})(ERatingState = exports.ERatingState || (exports.ERatingState = {}));
var EPaymentMethod;
(function (EPaymentMethod) {
    EPaymentMethod[EPaymentMethod["CASH"] = 0] = "CASH";
    EPaymentMethod[EPaymentMethod["STRIPE"] = 1] = "STRIPE";
})(EPaymentMethod = exports.EPaymentMethod || (exports.EPaymentMethod = {}));
var EOrderStatus;
(function (EOrderStatus) {
    // CREATED = 0, // was 0
    // NOT_PAID = 5, // was 5
    // CONFIRMED = 3, // was 3
    // SERVING = 1, // was 1
    // READY = 2, // was 2
    // WAITING_FOR_COURIER = 4, // was 4
    EOrderStatus[EOrderStatus["CREATED"] = 10] = "CREATED";
    EOrderStatus[EOrderStatus["NOT_PAID"] = 20] = "NOT_PAID";
    EOrderStatus[EOrderStatus["CONFIRMED"] = 30] = "CONFIRMED";
    EOrderStatus[EOrderStatus["SERVING"] = 40] = "SERVING";
    EOrderStatus[EOrderStatus["READY"] = 50] = "READY";
    EOrderStatus[EOrderStatus["WAITING_FOR_COURIER"] = 60] = "WAITING_FOR_COURIER";
    EOrderStatus[EOrderStatus["IN_RESTAURANT"] = 70] = "IN_RESTAURANT";
    EOrderStatus[EOrderStatus["CALL_CUSTOMER"] = 80] = "CALL_CUSTOMER";
})(EOrderStatus = exports.EOrderStatus || (exports.EOrderStatus = {}));
var EMessageStatus;
(function (EMessageStatus) {
    EMessageStatus[EMessageStatus["created"] = 0] = "created";
    EMessageStatus[EMessageStatus["sent"] = 100] = "sent";
    EMessageStatus[EMessageStatus["delayed"] = 200] = "delayed";
})(EMessageStatus = exports.EMessageStatus || (exports.EMessageStatus = {}));
var EventStatus;
(function (EventStatus) {
    EventStatus[EventStatus["pending"] = 0] = "pending";
    EventStatus[EventStatus["processing"] = 50] = "processing";
    EventStatus[EventStatus["processed"] = 100] = "processed";
    EventStatus[EventStatus["failed"] = -1] = "failed";
})(EventStatus = exports.EventStatus || (exports.EventStatus = {}));
var EventType;
(function (EventType) {
    EventType[EventType["PUBLISH_AD"] = 0] = "PUBLISH_AD";
    EventType[EventType["UNPUBLISH_AD"] = 5] = "UNPUBLISH_AD";
    EventType[EventType["MAILING"] = 10] = "MAILING";
    EventType[EventType["USERNAMES"] = 15] = "USERNAMES";
    EventType[EventType["PREORDER_NOTIFICATION"] = 25] = "PREORDER_NOTIFICATION";
    EventType[EventType["UPDATE_DISH_COEFFICIENTS"] = 30] = "UPDATE_DISH_COEFFICIENTS";
    EventType[EventType["NOTIFY_RESTAURATEUR_ABOUT_COOKING_TIME"] = 35] = "NOTIFY_RESTAURATEUR_ABOUT_COOKING_TIME";
})(EventType = exports.EventType || (exports.EventType = {}));
var EMailingStatus;
(function (EMailingStatus) {
    EMailingStatus[EMailingStatus["created"] = 0] = "created";
    EMailingStatus[EMailingStatus["processing"] = 50] = "processing";
    EMailingStatus[EMailingStatus["finished"] = 100] = "finished";
    EMailingStatus[EMailingStatus["failed"] = -1] = "failed";
})(EMailingStatus = exports.EMailingStatus || (exports.EMailingStatus = {}));
var EBotMessageStatus;
(function (EBotMessageStatus) {
    EBotMessageStatus[EBotMessageStatus["created"] = 0] = "created";
    EBotMessageStatus[EBotMessageStatus["deleted"] = 10] = "deleted";
})(EBotMessageStatus = exports.EBotMessageStatus || (exports.EBotMessageStatus = {}));
var EBotMessageType;
(function (EBotMessageType) {
    EBotMessageType[EBotMessageType["user"] = -1] = "user";
    EBotMessageType[EBotMessageType["simple"] = 0] = "simple";
    EBotMessageType[EBotMessageType["start"] = 1] = "start";
    EBotMessageType[EBotMessageType["menu"] = 2] = "menu";
    EBotMessageType[EBotMessageType["cheque"] = 3] = "cheque";
    EBotMessageType[EBotMessageType["chat"] = 4] = "chat";
    EBotMessageType[EBotMessageType["pagination"] = 5] = "pagination";
    EBotMessageType[EBotMessageType["invoice"] = 6] = "invoice";
    EBotMessageType[EBotMessageType["invoice_helper"] = 7] = "invoice_helper";
})(EBotMessageType = exports.EBotMessageType || (exports.EBotMessageType = {}));
